import Link from 'next/link'
import { ComponentProps } from 'react'

/*
  This component is used to prevent prefetching of links when they come into viewport, 
  to save bandwith for the sanity instance.

  Here is a relevant issue on the Next.js repository:
  https://github.com/vercel/next.js/discussions/24009

  By disabling prefetch, it still will prefetch the page when the user hovers over the link,
  as long as we are using the pages router from next. This is according to their documentation:
  https://nextjs.org/docs/pages/api-reference/components/link
*/

export default function NoPrefetchLink(props: ComponentProps<typeof Link>) {
  return (
    <Link {...props} prefetch={props.prefetch ?? false}>
      {props.children}
    </Link>
  )
};