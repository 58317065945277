import { MollerFont } from '@moller/design-system/fonts';
import { RegisterNyeMollerBilTheme, nyeMollerBilThemeClass } from '@moller/design-system/themes';
import { AppProps } from 'next/app';
import { useEffect } from 'react';
import ErrorBoundary from '../components/ErrorBoundary';
import { pageview } from '../lib/gtm';
import useHeaderVisible from '../store/useHeaderVisible';
import '../styles/global.css';
import initDataDog from '../utils/initDatadog';

initDataDog();

export function MyApp({ Component, pageProps, router }: AppProps) {
  const setVisible = useHeaderVisible((state) => state.setIsVisible);

  useEffect(() => {
    if (router._shallow) return;
    if (window._paq) pageview(router.asPath, document.title);
    setVisible(true);
  }, [router._shallow, router.asPath, setVisible]);

  useEffect(() => {
    document.body.classList.add(nyeMollerBilThemeClass);
  }, []);

  return (
    <>
      <RegisterNyeMollerBilTheme />
      <MollerFont />
      <ErrorBoundary>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Component {...pageProps} />
      </ErrorBoundary>
    </>
  );
}

export default MyApp;
